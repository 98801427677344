@use 'sass:map';
@use 'sass:meta';
@use 'sass:string';
@use 'AbstractFunctions' as *;
@use 'AbstractVariables' as *;

// HOW TO USE:
// @include name-of-mixin('variable') {

// }

/// Responsive breakpoint manager
/// @access public
/// @param {String} $breakpoint - Breakpoint
/// @requires $breakpoints
@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);
  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );
    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '+'Please make sure it is defined in `$breakpoints` map.';
  }
}

// Add box shadow
@mixin box-shadow($value) {
  -webkit-box-shadow: $value !important;
  -moz-box-shadow: $value !important;
  box-shadow: $value !important;
}

// Add transition
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

// Transform
@mixin transform($custom) {
  -webkit-transform: ($custom);
  -moz-transform: ($custom);
  -ms-transform: ($custom);
  -o-transform: ($custom);
  transform: ($custom);
}

// Filter
@mixin filter($custom) {
  -webkit-filter: $custom;
  -moz-filter: $custom;
  -ms-filter: $custom;
  -o-filter: $custom;
  filter: $custom;
}

/// @param  {Color} $color - color of placeholder text
// Change color of placeholder text
@mixin placeholder-color($color) {
  &::-moz-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

/// @param  {String} $name - keyframes name
/// @param  {Time} $duration - duration of animation
/// @param  {String} $timing-function - define speed curve of an animation
/// @param  {String} $fill-mode - define style for the element when the animation is not playing
// Generate basic non 'shorthand' animation
@mixin animation-basic($name, $duration, $timing-function, $fill-mode) {
  animation-name: $name;
  animation-duration: $duration;
  animation-fill-mode: $fill-mode;
  animation-timing-function: $timing-function;
}

/// @param  {String} $direction - direction of arrow (left,right,top, bottom)
/// @param  {Integer/Float} $width - arrow width
/// @param  {Integer/Float} $height - arrow height
/// @param  {Color} $color - color of arrow fill
// Generate arrow with specific size, direction and color
@mixin arrow($direction, $width, $height, $color) {
  width: 0;
  height: 0;
  border-style: solid;

  @if $direction == top {
    border-width: 0 ($width / 2) $height ($width / 2);
    border-color: transparent transparent $color transparent;
  }

  @if $direction == bottom {
    border-width: $height ($width / 2) 0 ($width / 2);
    border-color: $color transparent transparent transparent;
  }

  @if $direction == left {
    border-width: ($height / 2) $width ($height / 2) 0;
    border-color: transparent $color transparent transparent;
  }

  @if $direction == right {
    border-width: ($height / 2) 0 ($height / 2) $width;
    border-color: transparent transparent transparent $color;
  }
}

/// Stroke font-character (imitate font weight, but allow transition)
/// @param  {Integer} $stroke - Stroke width
/// @param  {Color}   $color  - Stroke color
/// @return {Style}           - text-shadow
@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}
